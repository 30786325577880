html {
  scroll-behavior: smooth !important;
}
.w-60 {
  width: 45%;
}
.w-20 {
  width: 20%;
}
.w-70 {
  width: 70%;
}
@media (max-width: 992px) {
  .w-60 {
    width: 70% !important;
  }
}
.fs-13 {
  font-size: 13px;
}
.btn-subscribe {
  border: 1px solid #f2f2f2 !important;
  margin-top: 2px;
}
.text-bold {
  font-weight: bold;
}
.login-bg-footer {
  background-color: #0c5782;
}
.login-bg-default {
  background-color: #fffaf5 !important;
}
.login-right {
  background-image: url("./../img/login-bg.png");
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-color: #fffaf5;
}
.login-right-wrapper {
  height: 100%;
}
.login-right-wrapper h3 {
  background-color: #fffaf5;
  padding: 3px;
  margin-top: 40%;
}
.login-right-slog {
  text-align: center;
}
.login-right-slog-wrapper {
  background-color: #fffaf5be;
  padding: 10px;
}
.login-right-line {
  height: 100%;
  width: 2px;
  background-color: #757575;
  margin: 0px 10px 0px 10px;
}
.heading-color {
  color: rgb(59, 29, 12);
}
.edit-profile-cover {
  position: absolute;
  color: #fff;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 99;
}
.edit-profile-avatar:hover {
  color: #ccc;
}
.card-profile-image:hover > .edit-profile-photo {
  display: block !important;
}
.edit-profile-photo {
  /* display: none; */
  color: #d2d1d1 !important;
  background-color: #00000079 !important;
  border-radius: 40% !important;
  font-size: 10px !important;
}
.edit-profile-photo:hover {
  color: #ffffff !important;
}
.edu-item {
  margin: 5px;
  padding: 5px;
}
.edu-img i {
  margin-right: 10px;
}
.edu-img img {
  margin-right: 10px;
}
.edu-item-inner {
  text-align: left;
}
.edu-item-inner span {
  font-size: 13px;
}
.sortable-list ul {
  padding: 0px !important;
}
.edu-item-actions i {
  margin: 10px;
}
.trash-btn:hover {
  color: #db1a1a;
}
.multiple-tag {
  background-color: #eeecec;
  padding: 4px 5px;
  border-radius: 5px;
  margin: 5px;
}
.multiple-tag span {
  font-size: 14px;
}
.multiple-tag i {
  font-size: 11px;
  margin-top: 3px;
}
.multiple-tag i:hover {
  color: #db1a1a;
  cursor: pointer;
}
.multiple-tag span {
  margin-right: 10px;
}
.case-columns {
  column-count: 2 !important;
}
@media (min-width: 576px) {
  .case-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 768px) {
  .case-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 992px) {
  .case-columns {
    column-count: 2 !important;
  }
}

@media (min-width: 1200px) {
  .case-columns {
    column-count: 2 !important;
  }
}
.ann-columns {
  column-count: 1 !important;
}
@media (min-width: 576px) {
  .ann-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 768px) {
  .ann-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 992px) {
  .ann-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 1200px) {
  .ann-columns {
    column-count: 2 !important;
  }
}
.h3-class h3 {
  margin: 5px;
}
.refer-out-box {
  border: 1px solid #e2e2e2;
}
.react-bootstrap-table td,
th {
  text-align: center !important;
}
.h-150 {
  height: 100px !important;
}
.event-box {
  border: 1px solid #e2e2e2;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.event-active {
  background-color: #5ccaa6;
  color: #fff !important;
}
.event-active h4 {
  color: #fff !important;
}
.change-cover {
  background-color: #ff8a4d;
  padding: 5px 10px;
  border-radius: 5px;
}
.change-cover:hover {
  background-color: #9a5732;
}
.page-item .page-link,
.page-item span {
  width: 50px !important;
  height: 50px !important;
}
.overlay-loading {
  background-color: #e2e2e2;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.3;
}
