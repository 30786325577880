.jc-center {
  justify-content: center;
}
.jc-space-around {
  justify-content: space-around;
}
.jc-space-between {
  justify-content: space-between;
}
.ai-center {
  align-items: center;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.ai-start {
  align-items: flex-start;
}
.flex-row {
  flex-direction: row !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.jc-end {
  justify-content: end;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.fs50 {
  font-size: 50px;
}
.fs30 {
  font-size: 30px;
}
.fs20 {
  font-size: 20px;
}
.fs14 {
  font-size: 14px;
}
.block {
  display: block;
}
.actionbtn:hover {
  color: red;
}
.pd-10 {
  margin: 10px !important;
  padding: 10px;
}
.invoice-print-wrapper {
  margin: 30px;
  padding: 30px;
}
.inline-display {
  display: inline !important;
}
.vertical-align-center {
  vertical-align: middle;
}
.float-right {
  float: right;
}
.mg-rl-15 {
  margin: 0 15px !important;
}
.mt-50p {
  margin-top: 20%;
  height: 500px;
}
.text-center {
  text-align: center !important;
}
.DatePicker__input {
  border: none;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 0;
}
.fs-22 {
  font-size: 22px;
}
.color-danger {
  color: #f5365c;
}
.color-success {
  color: #2dce89;
}
.price-before {
  text-decoration: line-through;
}
.sponsered-border {
  border: 1px solid #0ecdef !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.b-0 {
  bottom: 0px;
}
